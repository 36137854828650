<template>
  <div style="margin: 0; padding: 0" class="table-responsive">
    <table id="platform-table" class="table tb-dnd table-sm">
      <colgroup>
        <col style="width: 60px" />
        <col style="width: 250px" />
        <col style="width: 150px" />
        <col style="" />
      </colgroup>
      <thead>
        <tr>
          <th :class="isEditing ? 'edit-th-content text-center' : 'normal-th-content text-center'">
            <b-form-checkbox
              id="checkbox-all"
              size="lg"
              :disabled="!isEditing"
              name="checkbox-all"
              :value="true"
              :unchecked-value="false"
              :checked="
                allItems.length > 0 && allItems.length === checkedItems.length
              "
              @change="checkAll($event)"
            >
            </b-form-checkbox>
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            COUNTRY
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            LENS SELECTOR
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            REMOVE EMAIL FUNCTION
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            EMAIL DATA STORING
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            INCLUDE PRIVACY POLICY FOR APP SURVEY
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            HIDE WEB SURVEY PREVIEW
          </th>
          <th :class="isEditing ? 'edit-th-content text-right' : 'normal-th-content text-right'">
            <b-button
              v-if="isEditing"
              variant="outline-info"
              :disabled="disabledDeleteButton"
              @click="deleteSelected()"
            >
              Delete Selected
            </b-button>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(platform, index) in allItems" :key="index">
          <td class="text-center">
            <b-form-checkbox
              :id="`checkbox-${index}`"
              size="lg"
              :name="`checkbox-${index}`"
              :disabled="!isEditing"
              :checked="hasChecked(index)"
              @change="checkItem(index)"
            >
            </b-form-checkbox>
          </td>
          <td>
            <b-form-select
              v-if="platform.isNew && isEditing"
              v-model="platform.countryCode"
              :options="getCountriesDDL(platform.countryCode)"
              @change="changeItem(index, platform.countryCode)"
            ></b-form-select>
            <label v-else class="mt-2">
              {{ platform.countryName }}
            </label>
          </td>
          <td>
            <b-form-select
              v-if="isEditing"
              v-model="platform.lensSelectorAccess"
              :options="selectorOptions"
              @change="emitChange"
            ></b-form-select>
            <label v-if="!isEditing" class="mt-2">
              {{ platform.lensSelectorAccess ? "Yes" : "No" }}
            </label>
          </td>
          <td>
            <b-form-select
              v-if="platform.lensSelectorAccess && isEditing"
              v-model="platform.emailCollectionAccess"
              :options="selectorOptions"
              @change="emitChange"
            ></b-form-select>
            <label v-if="platform.lensSelectorAccess && !isEditing" class="mt-2">
              {{ platform.emailCollectionAccess ? "Yes" : "No" }}
            </label>
          </td>
          <td>
            <b-form-select
              v-if="platform.lensSelectorAccess && isEditing"
              v-model="platform.storeEmail"
              :options="selectorOptions"
              @change="emitChange"
            ></b-form-select>
            <label v-if="platform.lensSelectorAccess && !isEditing" class="mt-2">
              {{ platform.storeEmail ? "Yes" : "No" }}
            </label>
          </td>
          <td>
            <b-form-select
              v-if="platform.lensSelectorAccess && isEditing"
              v-model="platform.showPrivacy"
              :options="selectorOptions"
              @change="emitChange"
            ></b-form-select>
            <label v-if="platform.lensSelectorAccess && !isEditing" class="mt-2">
              {{ platform.showPrivacy ? "Yes" : "No" }}
            </label>
          </td>
          <td>
            <b-form-select
              v-if="platform.lensSelectorAccess && isEditing"
              v-model="platform.removeSurvey"
              :options="selectorOptions"
              @change="emitChange"
            ></b-form-select>
            <label v-if="platform.lensSelectorAccess && !isEditing" class="mt-2">
              {{ platform.removeSurvey ? "Yes" : "No" }}
            </label>
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
    <div class="mb-5">
      <button
        class="btn btn-primary width-76 px-5"
        style="margin-left: 60px"
        :disabled="!isEditing"
        @click="add"
      >
        + add more countries
      </button>
    </div>
    <table id="table-block" class="table tb-dnd table-sm position-fixed" style="display: none;">
      <colgroup>
        <col style="width: 60px" />
        <col style="width: 250px" />
        <col style="width: 150px" />
        <col style="" />
      </colgroup>
      <thead>
        <tr>
          <th :class="isEditing ? 'edit-th-content text-center' : 'normal-th-content text-center'">
            <b-form-checkbox
              id="checkbox-all"
              size="lg"
              :disabled="!isEditing"
              name="checkbox-all"
              :value="true"
              :unchecked-value="false"
              :checked="
                allItems.length > 0 && allItems.length === checkedItems.length
              "
              @change="checkAll($event)"
            >
            </b-form-checkbox>
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            COUNTRY
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            LENS SELECTOR
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            REMOVE EMAIL FUNCTION
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            EMAIL DATA STORING
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            INCLUDE PRIVACY POLICY FOR APP SURVEY
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            HIDE WEB SURVEY PREVIEW
          </th>
          <th :class="isEditing ? 'edit-th-content text-right' : 'normal-th-content text-right'">
            <b-button
              v-if="isEditing"
              variant="outline-info"
              :disabled="disabledDeleteButton"
              @click="deleteSelected()"
            >
              Delete Selected
            </b-button>
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>

import $ from 'jquery';

export default {
  name: "ManagePlatform",
  // components: {CheckBoxEditor},
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      allItems: [],
      checkedItems: [],
      deletedItems: [],
      selectorOptions: [
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      privacySelectorOptions: [
        { value: true, text: "Yes - include privacy policy" },
        { value: false, text: "No - remove privacy policy" },
      ],
      checkboxValue: false,
      removeSurveyCheckboxValue: false,
      top: 0,
    };
  },
  computed: {
    countries() {
      return this.$store.state.common.countries;
    },
    ddlCountries() {
      const allCountry = this.countries;
      const results = [];
      for (const [key, value] of Object.entries(allCountry)) {
        results.push({
          value: key,
          text: value,
        });
      }
      return results;
    },
    disabledDeleteButton() {
      return this.checkedItems.length === 0;
    },
  },
  watch: {
    isEditing() {
      this.checkedItems = [];
      this.deletedItems = [];
    },
    items() {
      this.allItems = this.items;
    },
  },
  updated() {
    const menu = $(".action-menu");
    let height = 223;
    if(menu.length) height = menu.height() + 65;
    this.top = height;
  },
  mounted() {
    this.allItems = this.items;
    this.windowOnScroll();
  },
  methods: {
    transfer(event, platform) {
      this.checkboxValue = event;
      this.allItems.filter(
        (item) => {
          if (item.countryCode == platform.countryCode) {
            item.emailCollectionAccess = this.checkboxValue;
          }
        }
      );
    },
    checkRemoveSurvey(event, platform) {
      this.removeSurveyCheckboxValue = event;
      this.allItems.filter(
        (item) => {
          if (item.countryCode == platform.countryCode) {
            item.removeSurvey = this.removeSurveyCheckboxValue;
          }
        }
      );
    },
    hasChecked(index) {
      return this.checkedItems.includes(index);
    },
    add() {
      if (
        this.allItems.length &&
        !this.allItems[this.allItems.length - 1].countryCode
      )
        return;
      this.allItems.push({
        isNew: true,
        countryCode: null,
        countryName: null,
        lensSelectorAccess: false,
        emailCollectionAccess: false,
        showPrivacy: false,
        removeSurvey: false,
        storeEmail: false,
      });
    },
    deleteSelected() {
      if (this.checkedItems.length === 0) return;
      const checkedCodes = this.allItems
        .filter((item, i) => this.checkedItems.includes(i))
        .map((x) => x.countryCode);
      const allItems = this.allItems.filter(
        (item, i) => !this.checkedItems.includes(i)
      );
      this.deletedItems = [...this.deletedItems, ...checkedCodes];
      this.checkedItems = [];
      this.allItems = allItems;

      this.emitChange();
    },
    checkAll(isAll) {
      if (isAll) {
        this.checkedItems = Array.from(Array(this.items.length).keys());
      } else {
        this.checkedItems = [];
      }
    },
    checkItem(index) {
      if (this.hasChecked(index)) {
        this.checkedItems.splice(
          this.checkedItems.findIndex((x) => x == index),
          1
        );
      } else {
        this.checkedItems.push(index);
      }
    },
    changeItem(index, countryCode) {
      const countryName = this.countries[countryCode];
      if (!this.allItems[index]) return;
      this.allItems[index].countryCode = countryCode;
      this.allItems[index].countryName = countryName;

      this.emitChange();
    },
    getCountriesDDL(exceptCode) {
      const ddlCountries = this.ddlCountries;
      let selectedCode = this.allItems.map((x) => x.countryCode);
      if (exceptCode) {
        selectedCode.splice(
          selectedCode.findIndex((x) => x === exceptCode),
          1
        );
      }
      selectedCode = selectedCode.filter(Boolean);
      return ddlCountries.filter((x) => !selectedCode.includes(x.value));
    },
    emitChange() {
      this.$emit("onUpdate", this.allItems, this.deletedItems);
    },
    windowOnScroll () {
      $(window).bind("scroll", (e) => {
        const currentTDWidth = $("#platform-table").width();
        const storageThWidth = [];
        let ind = 0;
        $("#platform-table th").each(function() {
          storageThWidth.push($(this)[0].clientWidth);
        });
        $("#table-block th").each(function() {
          $(this)[0].style.width = storageThWidth[ind++] + "px";
        });
        let offset = $(e.target).scrollTop();
        if(offset > 65) {
          const menu = $(".action-menu");
          let height = 223;
          if(menu.length) height = menu.height() + 65;
          console.log("menu.height(): "+ menu.height());
          $("#table-block").css({"width":currentTDWidth,"top": `calc(${height}px + 3rem)`, "display": "table", "zIndex": 1000});
        }else {
          $("#table-block").css({"display": "none"});
        }
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}
</style>
