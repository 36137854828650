<template>
  <div>
    <confirm-modal ref="confirmModal" />
    <div :class="'action-menu pt-5'" :style="{top: headerHeight + 'px'}">
      <h2 class="head-title">
        Manage product access for all countries in your organization.
      </h2>
      <b-row>
        <b-col cols="6" md="8">
          <p class="mb-3">
            All countries listed below will have access to the document delivery
            function. Use the edit button to add or delete a country and/or change
            the access.
          </p>
          <p class="mb-3">
            Removing an existing country, will not remove the associated user's
            access. Please go to Manage Users to delete the users.
          </p>
        </b-col>
        <b-col class="text-right" cols="6" sm="4">
          <template v-if="isEditing">
            <b-button variant="primary" class="mb-2 mr-2 px-5" @click="save">
              Save
            </b-button>
            <b-button variant="outline-info" class="mb-2 px-5" @click="unSave">
              Cancel
            </b-button>
          </template>
          <b-button
            v-if="!isEditing"
            variant="outline-info"
            class="mb-2 px-5"
            @click="setEditing(true)"
          >
            Edit content
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div>
      <PlatformTable
        :is-editing="isEditing"
        :items="items"
        @onUpdate="onUpdate"
      />
    </div>
  </div>
</template>

<script>
import ArrayUtils from "../../../common/ArrayUtils";
import {
MODAL_CANCEL,
MODAL_SAVE,
OK_CONFIRM,
} from "../../../common/CommonUtils";
import PlatformService from "../../../service/PlatformService";
import ConfirmModal from "../../common/ConfirmModal.vue";

import $ from "jquery";
import PlatformTable from "./components/PlatformTable";

export default {
  name: "ManagePlatform",
  components: {
    PlatformTable,
    ConfirmModal,
  },
  data() {
    return {
      items: [],
      originalItems: [],
      deletedItems: [],
      isEditing: false,
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.isEditing
            ? "edit-th-content"
            : "normal-th-content bg-light"
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    setEditing(isEditing) {
      this.isEditing = isEditing || !this.isEditing;
      this.clearFixedTable();
    },
    getAll() {
      const loader = this.$loading.show();
      PlatformService.getAll()
        .then((response) => {
          loader.hide();
          this.items = response.data;
          this.originalItems = ArrayUtils.clone(this.items);
        })
        .catch(this.catchError)
        .finally(() => {
          loader.hide();
        });
    },
    onUpdate(items, deletedItems) {
      this.items = items.filter((i) => i.countryCode && i.countryName);
      this.deletedItems = deletedItems;
    },
    save() {
      this.clearFixedTable();
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          const loader = this.$loading.show();
          if (this.deletedItems.length) {
            PlatformService.delete(this.deletedItems)
              .then(() => {
                console.log("Item deleted");
                this.deletedItems = [];
              })
              .catch(this.catchError);
          }
          PlatformService.upsert(this.items)
            .then(() => {
              this.setEditing(false);
              this.getAll();
            })
            .catch(this.catchError)
            .finally(() => {
              loader.hide();
            });
        }
      });
    },
    unSave() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        this.clearFixedTable();
        if (data === OK_CONFIRM) {
          this.isEditing = false;
          this.items = ArrayUtils.clone(this.originalItems);
        }
      });
    },
    catchError(reason) {
      const data = reason.response.data;
      console.error(data);
    },
    clearFixedTable () {
      if ($("#header-fixed").length) {
        $("#header-fixed").remove();
      }
    }
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}
</style>
